import { Session } from "@supabase/supabase-js";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import Stripe from "stripe";

import { LOCAL_STORAGE_SETTINGS_KEY } from "../lib/constants";
import { useCustomerInfo, useHostedCharts } from "../lib/queries";
import { languages } from "../locales/i18n";
import { colors, darkTheme } from "../slang/config";
import { supabase } from "../lib/supabaseClient";

import 
  OpenAI
 from 'openai'
type Theme = typeof colors;
//@ts-ignore
const openAI = new OpenAI({
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

// Stored in localStorage
export type UserSettings = {
  mode: "light" | "dark";
  language?: string;
};

const getPrompt = (str: string) => {
  const preprompt = `based on this flow chat langauge syntax: Node Label
  Text on a line creates a node with the text as the label
  
  Hello
  World
  an img
  Node ID, Classes, Attributes
  ID's
  Unique text value to identify a node
  
  Hello #x
  World #y
  Classes
  Use classes to group nodes
  
  Cat .animals
  Dog .animals
  Attributes
  Store any data associated to a node
  
  Hello #x.color_blue[letters=5]
  World #y.color_red[letters=5]
  Edges
  Creating an edge between two nodes is done by indenting the second node below the first
  
  Hello
    World
  an img
  Edge Label
  Text followed by colon+space creates an edge with the text as the label
  
  Hello
    to the: World
  an img
  Edge ID, Classes, Attributes
  Edges can also have ID's, classes, and attributes before the label
  
  Hello
    #x to the: World
  References
  References are used to create edges between nodes that are created elsewhere in the document
  
  Reference by Label
  Referencing a node by its exact label
  
  Hello
    World
      (Hello)
  an img
  Reference by ID
  Referencing a node by its unique ID
  
  Hello #x
    World #y
      (#x)
  an img
  Reference by Class
  Referencing multiple nodes with the same assigned class
  
  Cat .animals
  Dog .animals
  Animals
    (.animals)
  an img
  Leading References
  Draw an edge from multiple nodes by beginning the line with a reference
  
  Cat .animals
  Dog .animals
  
  (.animals)
    are pets
  an img
  Containers
  Containers are nodes that contain other nodes. They are declared using curly braces.
  
  Solar System {
    Earth
      Mars
  }
  
  based on this flow chart syntax generate flow chat representing a script or some flow for the gived prompt: ${str}
  
  and remember to not add any extra commendary, just reply with a markdown codeblock with the flow inside
  the flow should be well strucutured and well thought out and have proper hierarchy in the generated flow and strictly based it on language i desicribed above. AND THE STRUCUTURE OF THE FLOW SHOULD BE TREE LIKE`

  return preprompt;
}

// Get default languages
const browserLanguage = navigator.language.slice(0, 2);
const defaultLanguage = Object.keys(languages).includes(browserLanguage)
  ? browserLanguage
  : "en";

type mobileEditorTab = "text" | "graph";

type TAppContext = {
  updateUserSettings: (newSettings: Partial<UserSettings>) => void;
  theme: Theme;
  language: string;
  shareModal: boolean;
  aiModal: boolean;
  setShareModal: Dispatch<SetStateAction<boolean>>;
  setAIModal: Dispatch<SetStateAction<boolean>>;
  mobileEditorTab: mobileEditorTab;
  toggleMobileEditorTab: () => void;
  session: Session | null;
  customer?: CustomerInfo;
  customerIsLoading: boolean;
  /** Whether or not we've finished discovering if the user is auth'd */
  checkedSession: boolean;
  generateFlowWithAI: (prompt: string) => void;
  generatingFlow: boolean;
  aiError: string;
  aiFlow: string;
} & UserSettings;

type CustomerInfo = {
  customerId: string;
  subscription?: Stripe.Subscription;
};

export const AppContext = createContext({} as TAppContext);

const Provider = ({ children }: { children?: ReactNode }) => {
  const [shareModal, setShareModal] = useState(false);
  const [aiModal, setAIModal] = useState(false);
  const [userSettingsString, setUserSettings] = useLocalStorage(
    LOCAL_STORAGE_SETTINGS_KEY,
    "{}"
  );
  const [mobileEditorTab, setMobileEditorTab] =
    useState<mobileEditorTab>("text");
  const toggleMobileEditorTab = useCallback(
    () =>
      setMobileEditorTab((currentTab) =>
        currentTab === "text" ? "graph" : "text"
      ),
    []
  );

  const { settings, theme } = useMemo<{
    settings: UserSettings;
    theme: Theme;
  }>(() => {
    try {
      const settings = JSON.parse(userSettingsString);
      if (typeof settings.mode === "undefined") {
        settings.mode =
          window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light";
      }
      const theme = settings.mode === "dark" ? darkTheme : colors;

      return { settings, theme };
    } catch (e) {
      console.error(e);
      return { settings: {}, theme: colors };
    }
  }, [userSettingsString]);

  const updateUserSettings = useCallback(
    (newSettings: Partial<UserSettings>) => {
      setUserSettings(JSON.stringify({ ...settings, ...newSettings }));
    },
    [setUserSettings, settings]
  );

  useEffect(() => {
    // Remove chart that may have been stored, so
    // two indexes aren't shown on charts page
    window.localStorage.removeItem("flowcharts.fun:");
  }, []);

  const [checkedSession, setCheckedSession] = useState(false);
  const [session, setSession] = useState<Session | null>(null);

  const [generatingFlow, setGeneratingFlow] = useState(false);
  const [aiError, setAiError] = useState("");
  const [aiFlow, setAiFlow] = useState("");

  const generateFlowWithAI = async (prompt: string) => {
    setAIModal(false);
    setGeneratingFlow(true);
    setAiError("");
    try {
      const completion = await openAI.chat.completions.create({
        messages: [{ role: "user", content: getPrompt(prompt) }],
        model: "gpt-3.5-turbo",
        stream: true,
      });
      let flow = "";
      for await (const message of completion) {
        if(message.choices[0].delta?.content) {
          flow += message.choices[0].delta.content?.replace(/```/g, "");
          setAiFlow(flow.replaceAll("```", "").replace(/^(flow|markdown)/, ""));
        }
      }
    }
    catch (error) {
      console.error("Failed to generate flow", error);
      setAiError("Failed to generate flow. Please try again.");
    }
    setGeneratingFlow(false);
  }


  useEffect(() => {
    requestAnimationFrame(() => {
      if (!supabase) {
        setCheckedSession(true);
        return;
      }

      (async () => {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        setSession(session);
        setCheckedSession(true);
        supabase.auth.onAuthStateChange((_event, session) => {
          setSession(session);
        });
      })();
    });
  }, []);

  // Close Share Modal when navigating
  const { pathname } = useLocation();
  useEffect(() => setShareModal(false), [pathname]);
  useEffect(() => setAIModal(false), [pathname]);

  const { data: customer, isFetching: customerIsLoading } = useCustomerInfo();

  // Load hosted charts ahead of time
  useHostedCharts(session?.user?.id);

  // add class "dark" to body if dark mode
  useEffect(() => {
    if (settings.mode === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [settings.mode]);

  return (
    <AppContext.Provider
      value={{
        theme,
        updateUserSettings,
        setShareModal,
        setAIModal,
        aiModal,
        shareModal,
        aiFlow,
        mobileEditorTab,
        generateFlowWithAI,
        generatingFlow,
        aiError,
        toggleMobileEditorTab,
        session,
        customer,
        customerIsLoading,
        ...settings,
        language: settings.language ?? defaultLanguage,
        checkedSession,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default Provider;

export function useSession() {
  return useContext(AppContext).session;
}
